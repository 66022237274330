@import 'core/variables';
@import 'core/font';
@import 'core/system';
@import 'layout/header';
@import 'layout/footer';
@import 'module/buttons';
@import 'module/form';
@import 'module/cards';


html {
    scroll-behavior: smooth;
}

body {
	
}