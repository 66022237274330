//Font weight
$light: 300;
$regular: normal;
$medium: 500;
$semibold: 600;
$bold: bold;

//Font
$font: Montserrat;

//Colors
$blue-primary: #023468;
$blue-secondary: #004B8A;
$blue-link: #006AC3;
$blue-step: #0080EB;
$black: #2f3640;
$white: #fff;
$white-secondary: #F5F6FA;
$grey-primary: #718093;
$grey-secondary: #DCDDE1;
$grey-step: #AAB3C0;
$red: #e84118;
$red-light: #E49785;
$green: #259856;

$fa-font-path: "../webfonts";



*:focus {
	outline: none !important;
}

h2 {
	font-family: $font;
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 39px;
	color: $black;

	@media (max-width: 576px) {
		font-size: 26px;
		line-height: 30px;
	}
}

h4 {	
	font-family: $font;
	font-style: normal;
	font-weight: $light;
	font-size: 20px;
	line-height: 24px;

	color: $black;
}

p {
	font-family: $font;
	font-style: normal;

	&.question {
		font-weight: $bold;
		font-size: 14px;
		line-height: 17px;
		color: $black;
	}

	&.label {
		font-weight: $medium;
		font-size: 14px;
		line-height: 15px;
		color: $blue-secondary;
		text-transform: uppercase;
	}

	&.success {
		font-weight: $medium;
		font-size: 12px;
		line-height: 15px;
		color: $green;
	}

	.semibold {
		font-weight: $semibold;
	}

}

a {
	color: $blue-link;
	transition: 0.2s;

	&:hover {
		color: $blue-primary;
		text-decoration: none;
		transition: 0.2s;
	}
}

.btn {
	font-family: $font;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	border: none;
	position: relative;
	display: flex;
	width: 226px;
	height: 53px;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	.fa-chevron-left {
		position: relative;
		right: 15px;
	}

	.fa-chevron-right {
		position: relative;
		left: 15px;
	}

	&:focus {
		box-shadow: none !important;
	}

	&.btn-primary {
		background: $blue-secondary;
		color: $white;
		transition: all 180ms;
		bottom: 0;
		

		&:hover {
			position: relative;
			bottom: 3px;
			background: $blue-secondary;
			color: $white;
			transition: all 180ms;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}

		&:focus {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}

		&:not(:disabled):not(.disabled):active {
			background-color: $blue-secondary;
			border: none;
			color: $white;
		}

		&.success {
			background: $green;
		}

		&.error {
			background: $red;
		}
	}

	&.btn-secondary {
		background: $grey-secondary;
		color: $grey-primary;
		transition: all 180ms;
		bottom: 0;
		

		&:hover {
			position: relative;
			bottom: 3px;
			background: $grey-secondary;
			color: $grey-primary;
			transition: all 180ms;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}

		&:focus {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}

		&:not(:disabled):not(.disabled):active {
			background-color: $grey-secondary;
			border: none;
			color: $grey-primary;
		}

		&.success {
			background: $green;
		}

		&.error {
			background: $red;
		}
	}

	.spinner-border {
		display: none;
		position: absolute;
		top: 11px;
    	left: 43%;
	}
}