body {
	background: #F5F6FA;
	width: 100%;
	height: 100%;
}

#complaint {
	padding-top: 66px;
	padding-bottom: 66px;

	@media (max-width: 768px){
		padding-top: 26px;
		padding-bottom: 65px;
	}

	.container {
		display: flex;
		justify-content: center;
	}

	.complaint-content {
		width: 100%;
		text-align: center;

		.complaint-header {

			&.success {

				.title {
					color: $green;
				}
			}

			&.error {

				.title {
					color: $red;
				}
			}

			img {
				max-width: 246px;
				height: auto;
				width: 100%;
				display: block;
				margin: 0 auto;
				margin-bottom: 94px;

				@media (max-width: 576px) {
					max-width: 160px;
					margin-bottom: 54px;
				}
			}

			.label {
				margin-bottom: 12px;
			}

			h2 {
				margin-bottom: 60px;
			}

			.title {

				i {
					font-size: 26px;
					color: $green;
				}
			}
		}

		.complaint-form {
			max-width: 372px;
			min-height: 426px;
			height: 100%;
			width: 100%;
			display: block;
			position: relative;

			@media (max-width: 768px) {
				max-width: 100%;
				height: 100%;
				padding-right: 0;
				padding-bottom: 50px;
			}

			.error-description {
				color: $red;
				font-size: 14px;
				font-family: $font;
				font-weight: $semibold;
				position: absolute;
				bottom: 0;
			}
		}

		.complaint-steps {
			max-width: 372px;
			width: 100%;

			@media (max-width: 768px) {
				display: flex;
				justify-content: space-between;
				max-width: 100%;
				position: relative;
				padding-bottom: 85px;
			}

			.step {
				cursor: pointer;

				@media (max-width: 768px) {
					display: flex;
					width: 100%;

					&:last-child {
						max-width: 36px;
					}				
				}

				&.active {

					.step-header {

						.number {
							border: 2px solid $blue-step;
							color: $blue-step;
						}

						.name {
							font-size: 16px;
							color: $blue-step;
							font-weight: $semibold;

							@media (max-width: 768px) {
								display: block;
							    position: absolute;
							    top: 85px;
							    left: 0;
							    width: 100%;
							    text-align: center;
							}
						}
					}

					.step-line {
						border-right: 2px solid $blue-step;

						@media (max-width: 768px) {
							border-right: none;
							border-top: 2px solid $blue-step;
							margin-top: 17px;
							width: 100%;
						}
					}
				}

				&.complete {

					.step-header {

						.number {
							border: 2px solid $grey-primary;
							color: $grey-primary;
						}

						.name {
							color: $grey-primary;
							font-weight: $medium;
						}
					}

					.step-line {
						border-right: 2px solid $grey-primary;

						@media (max-width: 768px) {
							border-right: none;
							border-top: 2px solid $grey-primary;
							margin-top: 17px;
							width: 100%;
						}
					}
				}

				.step-header {
					display: flex;
					justify-content: flex-end;

					.number {
						border: 2px solid $grey-step;
						width: 36px;
						min-width: 36px;
						height: 36px;
						border-radius: 50px;
						font-family: $font;
						font-style: normal;
						font-weight: $semibold;
						font-size: 18px;
						line-height: 27px;
						color: $grey-step;
						display: flex;
						justify-content: center;
						align-items: center;
						padding-top: 1px;
					}

					.name {
						text-align: right;
						font-family: $font;
						font-style: normal;
						font-weight: $medium;
						font-size: 14px;
						line-height: 17px;
						color: $grey-step;
						display: flex;
						align-items: center;
						padding-right: 16px;

						@media (max-width: 768px) {
							display: none;
						}
					}
				}

				.step-line {
					border-right: 2px solid $grey-step;
					min-height: 35px;
					height: 100%;
					margin-right: 17px;

					@media (max-width: 768px) {
						min-height: auto;
						height: 19px;
						margin-right: 0;
						border-right: none;
						border-top: 2px solid $grey-step;
						margin-top: 17px;
						width: 100%;
					}
				}
			}
		}
		
		.complaint-buttons {
			padding-top: 35px;
			display: flex;
			justify-content: space-between;
			width: 100%;

			@media (max-width: 768px) {
				position: fixed;
				bottom: 0;
				left: 0;
				z-index: 1000;

				.btn {
					display: block;
					border-radius: 0;
					width: 100%;



						&:hover {
							bottom: 0px;
							-webkit-box-shadow: none;
							-moz-box-shadow: none;
							box-shadow: none;
						}
					

					
				}
			}
		}

		.complaint-success {
			text-align: center;

			h4 {
				margin-bottom: 6px;
			}

			.success {
				font-weight: $medium;
				font-size: 12px;
				color: $grey-primary;
				margin-bottom: 30px;

				.semibold {
					color: $green;
				}
			}

			img {
				width: 100%;
				max-width: 360px;
				display: block;
				margin: 0 auto;
				height: auto;
			}
		}

		.complaint-error {
			text-align: center;

			h4 {
				max-width: 600px;
				margin: 0 auto;
				margin-bottom: 16px;
			}

			.error {
				font-weight: $medium;
				font-size: 12px;
				color: $grey-primary;
				margin-bottom: 6px;

				i {
					color: $red;
					font-size: 12px;
				}

				.semibold {
					color: $green;
				}
			}

			img {
				width: 100%;
				max-width: 300px;
				display: block;
				margin: 0 auto;
				margin-top: 30px;
				height: auto;
			}
		}
	}
}