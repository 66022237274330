.liberfly-input__text {
	position: relative;
	height: 53px;
	width: 100%;
	margin-bottom: 20px;

	&.error {

		label {
			color: $red;
		}

		.asterisk {
			display: block;
		}
	}

	.asterisk {
		z-index: 5;
		display: none;
		position: absolute;
	    font-family: $font;
	    font-weight: $semibold;
	    color: $red;
	    font-size: 18px;
	    top: 0px;
    	left: 5px;
	}

	label {
		pointer-events: none;
		position: absolute;
		top: 19px;
		left: 25px;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $grey-primary;
		text-transform: uppercase;
		transition: 0.1s;

		&.error {
			color: $red;
		}

		&.focus {
			transition: 0.1s;
			font-size: 12px;
			top: 5px;
			left: 15px;
		}

		@media (max-width: 576px) {
			font-size: 12px;
			left: 15px;

			&.focus {
				font-size: 10px;
			}
		}
	}

	.form-control {
		border-radius: 5px;
		width: 100%;
		height: 100%;
		background: $grey-secondary;
		border: 2px solid $grey-secondary;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $black;
		padding-top: 27px;
		-moz-appearance:none; /* Firefox */
	    -webkit-appearance:none; /* Safari and Chrome */
	    appearance:none;

		&:focus {
			outline: none;
			box-shadow: none;
			border: 2px solid $black;
			background: $white;
		}

		&.focus {
			outline: none;
			box-shadow: none;
			border: 2px solid $black;
			background: $white;
		}
	}
}


.liberfly-input__select {
	position: relative;
	height: 53px;
	width: 100%;
	margin-bottom: 20px;
	cursor: pointer;

	&.error {

		.asterisk {
			display: block;
		}

		label {
			color: $red;
		}

		i {
			color: $red;
		}

		.select-styled {
			color: $red;
		}

		.select2-selection--single {

			.select2-selection__rendered {

				.select2-selection__placeholder {
					color: $red;
				}
			}
		}
	}

	.asterisk {
		z-index: 5;
		display: none;
		position: absolute;
	    font-family: $font;
	    font-weight: $semibold;
	    color: $red;
	    font-size: 18px;
	    top: 0px;
    	left: 5px;
	}

	&.selected {

		label {
			display: block;
		}

		.fa-chevron-down {
			color: $black;
		}

		.select {
			font-family: $font;
			font-style: normal;
			font-weight: $semibold;
			font-size: 14px;
			line-height: 17px;
			color: $black;

			@media (max-width: 576px) {
		    	font-size: 12px;
		    }
		}

		.select-styled {
			background: $white;
			border: 2px solid $black;
			text-transform: none;
			padding-top: 27px;
			padding-left: 14px;
		}
	}

	.fa-chevron-down {
		pointer-events: none;
		color: $grey-primary;
		font-size: 18px;
		top: 18px;
		right: 18px;
		position: absolute;
		z-index: 1;

		&.rotate {
			-ms-transform: rotate(180deg); /* IE 9 */
			-webkit-transform: rotate(180deg); /* Safari 3-8 */
			transform: rotate(180deg);
		}
	}

	label {
		pointer-events: none;
		position: absolute;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		line-height: 17px;
		color: $grey-primary;
		text-transform: uppercase;
		font-size: 12px;
		top: 5px;
		left: 15px;
		z-index: 1;
		display: none;

		@media (max-width: 576px) {
			font-size: 10px;
		}

		&.error {
			color: $red;
		}
	}

	.select-hidden {
		display: none;
		visibility: hidden;
		padding-right: 10px;
	}

	.select {
	    position: relative;
		height: 100%;
		width: 100%;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $grey-primary;

		@media (max-width: 576px) {
	    	font-size: 12px;
	    }
	}

	.select-styled {
		white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    padding-right: 40px;
		position: absolute; 
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		background-color: $grey-secondary;
		border-radius: 5px;
		text-align: left;
		padding-top: 17px;
		padding-left: 23px;
		text-transform: uppercase;
		border: 2px solid $grey-secondary;

		&.error {
			color: $red;
		}

		@media (max-width: 576px) {
	    	padding-left: 13px;
	    }

		&.active {
			border-bottom-left-radius: 0;
		    border-bottom-right-radius: 0;
		    border: 2px solid #000;
		    border-bottom: none;
		    background: #fff;
		}

		&:after {
			content:"";
			position: absolute;
			top: 27px;
			left: 15px;
		}
	}

	.select-options {
		display: none; 
		position: absolute;
	    top: 100%;
	    right: 0;
	    left: 0;
	    z-index: 999;
	    margin: 0;
	    padding: 0;
	    list-style: none;
	    background-color: #dcdde1;
	    border-bottom-left-radius: 5px;
	    border-bottom-right-radius: 5px;
	    border: 2px solid #000;
	    background: #fff;
	    border-top: none;
	    padding-bottom: 10px;
	    max-height: 132px;
    	overflow-y: auto;

		li {
			margin: 0;
			padding: 6px 22px;
			cursor: pointer;
			text-align: left;
			font-weight: $medium;

			&:hover {
			    color: $black;
			    background: $white-secondary;
			}

			&[rel="hide"] {
			    display: none;
			}
		}
	}

	.select2 {
		width: 100% !important;

		&.select2-container--open {

			.select2-selection--single {
				background-color: $white;
	    		border: 2px solid $black;
	    		border-bottom: none;
			}
		}
	}

	.select2-selection--single {
		width: 100%;
		min-height: 53px;
		background-color: $grey-secondary;
	    border: 2px solid $grey-secondary;
	    border-radius: 5px;

	    &.active {
	    	background-color: $white;
	    	border: 2px solid $black;

	    	.select2-selection__rendered {
	    		text-transform: none;
	    		padding-top: 28px;
				padding-left: 13px;
				color: $black;
	    	}
	    }

		.select2-selection__rendered {
			text-align: left;
			font-family: $font;
			font-style: normal;
			font-weight: $semibold;
			font-size: 14px;
			line-height: 17px;
			padding-top: 17px;
			padding-left: 23px;
			padding-right: 42px;

			color: $grey-primary;
			text-transform: uppercase;

			@media (max-width: 576px) {
		    	font-size: 12px;
		    	padding-left: 13px;
		    }

			.select2-selection__placeholder {
				color: $grey-primary;

				&.error {
					color: $red;
				}
			}
		}

		.select2-selection__arrow {
			display: none;
		}
	}
}

.select2-dropdown {
	background: $white;
	border: 2px solid $black;
	border-top: none;

	.select2-search--dropdown {
		padding-left: 10px;
		padding-right: 10px;

		.select2-search__field {
			border: none;
			border-radius: 3px;
			font-family: $font;
			font-style: normal;
			font-weight: $semibold;
			font-size: 14px;
			line-height: 17px;
			color: $black;
			background: $grey-secondary;
			padding: 10px;
		}
	}

	.select2-results {

		.select2-results__options {
			max-height: 132px;
		}

		.select2-results__option {
			text-align: left;
			font-family: $font;
			font-style: normal;
			font-weight: $semibold;
			font-size: 14px;
			line-height: 17px;
			padding-left: 22px;
			padding-right: 22px;
			color: $grey-primary;
			background: $white;
			font-weight: $medium;

			@media (max-width: 576px) {
				font-size: 12px;
			}

			&.select2-results__option--highlighted {
				color: $black;
				background: $white-secondary;
			}
		}
	}

	
}

.liberfly-phone {
	width: 100%;
	height: 53px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	.phone-block {
		padding-right: 12px;
	}

}

.radio {
	text-align: left;

	.radio-input:checked,
	.radio-input:not(:checked) {
	    position: absolute;
	    left: -9999px;
	}
	.radio-input:checked + label,
	.radio-input:not(:checked) + label
	{
	    position: relative;
	    padding-left: 30px;
	    cursor: pointer;
	    line-height: 20px;
	    display: inline-block;
	    color: $grey-primary;
	}
	.radio-input:checked + label:before,
	.radio-input:not(:checked) + label:before {
	    content: '';
	    position: absolute;
	    left: 0;
	    top: 0;
	    width: 23px;
	    height: 23px;
	    border: none;
	    border-radius: 100%;
	    background: $grey-secondary;
	}
	.radio-input:checked + label:after,
	.radio-input:not(:checked) + label:after {
	    content: '';
	    width: 23px;
	    height: 23px;
	    background: url('../img/check.png') no-repeat center center;
	    background-color: $blue-secondary;
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    border-radius: 100%;
	    -webkit-transition: all 0.2s ease;
	    transition: all 0.2s ease;
	}
	.radio-input:not(:checked) + label:after {
	    opacity: 0;
	    -webkit-transform: scale(0);
	    transform: scale(0);
	}
	.radio-input:checked + label:after {
	    opacity: 1;
	    -webkit-transform: scale(1);
	    transform: scale(1);
	}

	.radio-label {
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $grey-primary;
		margin-bottom: 3px;
		padding-top: 2px;
	}
}

.liberfly-input__checkbox {
	text-align: left;
	margin-bottom: 12px;
	position: relative;

	&.error {

		.asterisk {
			display: block;
		}

		.checkbox-input:checked + label:before,
		.checkbox-input:not(:checked) + label:before {
			background: $red-light;
		}
	}

	.asterisk {
		z-index: 5;
	    position: absolute;
	    font-family: Montserrat;
	    font-weight: 600;
	    color: #e84118;
	    font-size: 20px;
	    top: 1px;
	    left: 29px;
	    display: none;
	}

	.checkbox-input:checked,
	.checkbox-input:not(:checked) {
	    position: absolute;
	    left: -9999px;
	}
	.checkbox-input:checked + label,
	.checkbox-input:not(:checked) + label
	{
	    position: relative;
	    padding-left: 40px;
	    cursor: pointer;
	    line-height: 20px;
	    display: inline-block;
	    color: $grey-primary;
	}
	.checkbox-input:checked + label:before,
	.checkbox-input:not(:checked) + label:before {
	    content: '';
	    position: absolute;
	    left: 0;
	    top: 0;
	    width: 23px;
	    height: 23px;
	    border: none;
	    border-radius: 3px;
	    background: $grey-secondary;
	}
	.checkbox-input:checked + label:after,
	.checkbox-input:not(:checked) + label:after {
	    content: '';
	    width: 23px;
	    height: 23px;
	    background: url('../img/check.png') no-repeat center center;
	    background-color: $blue-secondary;
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    border-radius: 3px;
	    -webkit-transition: all 0.2s ease;
	    transition: all 0.2s ease;
	}
	.checkbox-input:not(:checked) + label:after {
	    opacity: 0;
	    -webkit-transform: scale(0);
	    transform: scale(0);
	}
	.checkbox-input:checked + label:after {
	    opacity: 1;
	    -webkit-transform: scale(1);
	    transform: scale(1);
	}

	&.error {

		.checkbox-input:checked + label:before,
		.checkbox-input:not(:checked) + label:before {
			background: $red-light;
		}
	}

	.checkbox-label {
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $grey-primary;
		margin-bottom: 3px;
		padding-top: 2px;

		img {
			height: 22px;
			width: auto;
		}
	}
}

.liberfly-input__textarea {
	position: relative;
	height: 169px;
	width: 100%;
	margin-bottom: 20px;

	&.error {

		.asterisk {
			display: block;
		}

		label {
			color: $red;
		}
	}

	.asterisk {
		z-index: 5;
		display: none;
		position: absolute;
	    font-family: $font;
	    font-weight: $semibold;
	    color: $red;
	    font-size: 18px;
	    top: 0px;
    	left: 5px;
	}

	label {
		pointer-events: none;
		position: absolute;
		top: 19px;
		left: 25px;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $grey-primary;
		text-transform: uppercase;
		transition: 0.1s;
		padding-right: 25px;

		&.error {
			color: $red;
		}

		&.focus {
			transition: .1s;
		    font-size: 12px;
		    top: 2px;
		    left: 13px;
		    background: #fff;
		    display: block;
		    width: 93%;
		    height: 20px;
		    text-align: left;
		    padding-right: 0px;
		}

		@media (max-width: 576px) {
			font-size: 12px;
			left: 15px;

			&.focus {
				font-size: 10px;
			}
		}
	}

	.form-control {
		resize: none;
		border-radius: 5px;
		width: 100%;
		height: 100%;
		background: $grey-secondary;
		border: 2px solid $grey-secondary;
		font-family: $font;
		font-style: normal;
		font-weight: $semibold;
		font-size: 14px;
		line-height: 17px;
		color: $black;
		padding-top: 27px;
		padding-right: 30px;
		-moz-appearance:none; /* Firefox */
	    -webkit-appearance:none; /* Safari and Chrome */
	    appearance:none;

	    @media (max-width: 576px) {
	    	font-size: 12px;
	    }

	    &::-webkit-scrollbar-track
		{
			-webkit-box-shadow: none;
			border-radius: 1px;
			background-color: inherit;
		}

		&::-webkit-scrollbar
		{
			width: 5px;
			background-color: inherit;
		}

		&::-webkit-scrollbar-thumb
		{
			border-radius: 1px;
			-webkit-box-shadow: none;
			background-color: $grey-primary;
		}

		&:focus {
			outline: none;
			box-shadow: none;
			border: 2px solid $black;
			background: $white;
		}

		&.focus {
			outline: none;
			box-shadow: none;
			border: 2px solid $black;
			background: $white;
		}
	}
}